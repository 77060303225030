:root {
  --color-accent: #BC3733;
  --color-shade-1: #e8e7e7;
  --color-shade-2: #f7f7f7;
  --color-shade-3: #e3e3e3;
  --color-shade-4: #efefef;
  --color-dark: #000000;
  --color-icon: #2e3a59;
  --font-variant-1: 14px/20px Helvetica;
  --font-variant-2: 18px/25px Helvetica;
  --font-variant-3: 24px/30px Helvetica;
  --font-variant-4: 16px/20px Helvetica;
  --font-variant-5: 12px/16px Helvetica;
  --font-variant-6: 14px/16px Helvetica;
  --font-variant-7: 20px/25px Helvetica;
  --border-variant-1: 1px solid var(--color-shade-3);
  --border-variant-2: 1px solid #cfd0cf;
}

html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
  transition-duration: 500ms;
}

body {
  margin: 0;
  overflow: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  display: flex;
  flex-flow: column;
  height: 100vh;
}

.dashboard, .dashboard > * {
  height: 100%;
  width: 100%;
  will-change: width;
}

.height-100percent {
  height: 100%;
}

.collapsible-sidebar {
  background-color: var(--color-shade-2);
  border-right: var(--border-variant-1);
  display: flex;
  flex-flow: column;
  height: 100%;
  max-width: 400px;
  overflow-y: auto;
  width: 25%;
  will-change: width;
  min-width: 350px;
}

.collapsible-sidebar,  .collapsible-sidebar > div {
  border-collapse: collapse;
}

.dashboard-header {
  align-items: center;
  border-bottom: var(--border-variant-1);
  display: flex;
  height: 80px;
  justify-content: space-between;
  position: absolute;
  width: 100%;
}

.dashboard-sidebar__wrapper {
  display: flex;
  height: 100%;
  flex: 0 1 auto;
  padding-top: 80px;
}

.site-logo__inside-wrapper {
  height: 100%;
  display: flex;
}

.site-logo {
  height: 100%;
  padding: 22px;
  position: relative;
}

.site-title {
  font: 32px/40px Helvetica;
}

.sidebar__header-title h4 {
  font: var(--font-variant-7);
  margin: 0;
}

.sidebar__header-title {
  display: flex;
  align-items: center;
}

.sidebar__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.margin-right {
  margin-right: 25px;
}

svg, svg * {
  transition-duration: 0ms;
}

.loading-screen {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: white;
  z-index: 1000;
  transition-duration: 0ms;
}

.loading-screen-main {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%
}

.progress-bar-wrapper {
  width: 100%;
  height: 6px;
}

.progress-bar-track {
  width: 100%;
  height: 6px;
}
.progress-bar-inner {
  width: 0;
  height: 6px;
}

.sidebar-filter-count {
  color: var(--color-accent);
  font: var(--font-variant-1);
  margin-left: 10px;
}

.icon.tooltip {
  color: #00000040;
  margin-left: 5px;
  transform: translate(0px, 1px);
}

.collapsed-sidebar .sidebar-filter-count.all {
  margin-left: 0;
}

.sidebar-row-item {
  border-bottom: var(--border-variant-1);
  width: 100%;
  padding: 20px;
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.sidebar-row-item.expandable {
  display: flex;
  flex-flow: column;
  max-height: 60px;
}

.sidebar-row-item.expandable .sidebar-row-item-info {
  display: flex;
  align-items: center;
  width: 100%;
}

.sidebar-row-item.expandable.collapsed .expanded-content {
  display: none
}

.sidebar-row-item.expandable.expanded .expanded-content {
  display: flex;
  flex-flow: column;
}

.sidebar-row-item.expandable .expanded-content {
  margin-top: 20px;
}

.sidebar-row-item.expandable.expanded {
  background-color: var(--color-shade-4);
  max-height: 1000px;
}

.sidebar-row-item.vert > *:not(:last-child) {
  margin-bottom: 10px;
}

.contact-request-form {
  height: 100%;
  display: flex;
  flex-flow: column;
}

.sidebar__footer, .contact-request-form__footer {
  display: flex;
  justify-content: flex-end;
}

.sidebar__footer, .contact-request-form__footer {
  border-top: var(--border-variant-1);
  margin-top: auto;
  padding: 20px;
}

.modal-expanded-content.expanded {
  max-height: 1000px;
  display: flex;
}

.monetary-range-input__wrapper {
  display: flex;
}

.monetary-input-signifier {
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 4px;
  font: var(--font-variant-2);
  color: var(--color-accent);
  border: var(--border-variant-2);
  border-right: none;
  background-color: white;
}

.modal-expanded-content.collapsed {
  max-height: 0;
  display: none;
}

.custom-select__outermost-wrapper {
  width: 100%;
}

button, .button {
  background-color: transparent;
  padding: 8px 18px;
  border: none;
  cursor: pointer;
  font: var(--font-variant-1);
  text-decoration: none;
}

.button.variant-1, button.variant-1 {
  background-color: var(--color-accent);
  color: white
}

.button.variant-1.actionable {
  background-color: var(--color-accent);
  color: white
}

.button.action-button {
  padding: 15px 30px;
}

.button.inactive {
  background-color: #e8e7e7;
  color: white;
  cursor: not-allowed;
}

.button.variant-2 {
  background-color: #000;
  color: white
}

.icon.refresh {
  margin-left: 6px;
  color: white
}

button.es {
  padding: 10px 20px
}

.center-text {
  text-align: center !important;
}

.contact-request-form-title.center-text {
  width: 100%;
}

.contact-request-form-action__wrapper {
  display: flex;
  justify-content: center;
}

.contact-request-form__body.center {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

button.action-button {
  padding: 10px 0;
  flex: 1 1 0;
  text-transform: uppercase;
}

button.action-button:not(:last-child) {
  margin-right: 10px;
}

select {
  appearance: none;
  background-color: transparent;
  border: none;
  padding: 0 1em 0 0;
  margin: 0;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
  outline: none;
  padding: 18px 20px;
}

.select {
  width: 100%;
  cursor: pointer;
  background-color: var(--color-shade-1);
  display: grid;
  grid-template-areas: "select";
  align-items: center;
  position: relative;
  font: var(--font-variant-2);
}

.custom-select-button {
  width: 100%;
  cursor: pointer;
  background-color: transparent;
  position: relative;
  font: var(--font-variant-6);
  outline: none;
  padding: 12px 20px;
  -webkit-user-select: none;
  user-select: none;
  color: transparent;
  text-shadow: 0 0 0 #000;
  z-index: 2;
  border: none;
}

.custom-select-button.small {
  width: 90px;
  height: 40px;
  cursor: pointer;
  background-color: transparent;
  position: relative;
  outline: none;
  padding: 8px 20px;
  border: var(--border-variant-2);
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  color : transparent;
  text-shadow : 0 0 0 #000;
  padding-right: 16.5px;
}

.custom-select-button.multi {
  border: var(--border-variant-2);
}

#cancel-api {
  color: var(--color-accent);
  text-transform: capitalize;
  cursor: pointer;
}

.select-dropdown {
  display: none;
  flex-flow: column;
  font: var(--font-variant-6);
  background-color: var(--color-shade-1);
  border-top: var(--border-variant-2);
  position: absolute;
  top: 100%;
  width: 100%;
}

.select-dropdown.bx {
  border: var(--border-variant-2);
}

.multi-select-dropdown {
  display: none;
  flex-flow: column;
  font: var(--font-variant-2);
  background-color: white;
  border: var(--border-variant-2);
  position: absolute;
  top: 100%;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
}

.select-dropdown.small {
  background-color: white;
  width: 90px
}

.select-dropdown > div {
  cursor: pointer;
  padding: 5px 20px;
}

.multi-select-dropdown > div {
  cursor: pointer;
  padding: 5px 20px;
}

.topic-dropdown__wrapper {
  display: flex;
  align-items: center;
}

.topic-dropdown__wrapper p {
  font: var(--font-variant-2);
  margin-left: 10px;
}

.select-dropdown > div:hover {
  background-color: #cfcfcf;
}

.multi-select-dropdown > div:hover {
  background-color: #cfcfcf;
}


.select-dropdown > div:first-child {
  margin-top: 10px
}

.multi-select-dropdown > div:first-child {
  margin-top: 10px
}

.select-dropdown > div:last-child {
  margin-bottom: 10px
}

.multi-select-dropdown > div:last-child {
  margin-bottom: 10px
}

.select-dropdown.active, .multi-select-dropdown.active {
  display: flex;
}

select::-ms-expand {
  display: none;
}

.custom-select__outer-wrapper {
  position: relative;
}

.toggle-button-group {
  display: flex;
}

.fill-width {
  width: 100%
}

.toggle-button-group.fill-width .toggle-button {
  flex: 1 1 34%;
}

.toggle-button-group.fill-width .toggle-button:not(:last-child) {
  margin-right: 2px;
}

.toggle-button {
  background-color: var(--color-shade-1);
  padding-top: 12px;
  padding-bottom: 12px;
  font: var(--font-variant-6)
}

.toggle-button-group .toggle-button.active {
  background-color: var(--color-dark);
  color: white;
}

.collapsed-sidebar {
  height: 100%;
  width: 75px;
  background-color: var(--color-shade-2);
  border-right: var(--border-variant-1);
  display: flex;
  flex-flow: column;
  align-items: center;
}

.collapsible-sidebar.collapsed {
  height: 100%;
  width: 75px;
  background-color: var(--color-shade-2);
  border-right: var(--border-variant-1);
  display: flex;
  flex-flow: column;
  align-items: center;
  overflow-x: hidden;
  min-width: 0;
}

.collapsible-sidebar.collapsed > .sidebar-expanded-content__wrapper {
  width: 0;
  overflow-x: hidden;
}

.sidebar-open {
  margin-top: 25px;
  margin-bottom: 5px;
}

.sidebar-open__param {
  color: var(--color-accent) !important;
  margin-top: 15px;
  margin-bottom: 5px;
}

.sidebar-collapsed-content__wrapper {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.sidebar-row-item__title {
  font: var(--font-variant-1)
}

.expander {
  margin-left: auto;
}

.expander.no-ml {
  margin-left: unset !important;
}

.icon {
  color: var(--color-icon)
}

.icon.white {
  color: white
}

.dev-note {
  color: var(--color-accent);
  margin: 10px;
  margin-top: 20px;
  font: 10px/15px Helvetica;
}

.range-single-input {
  width: 100px;
  height: 40px; 
  border: var(--border-variant-2);
  font: var(--font-variant-2);
  outline: none;
  text-align: center;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.range-filter__outer-wrapper {
  width: 100%
}

.range-filter-input__wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%
}

.search-input {
  background-color: #fff;
  outline: none;
  width: 100%;
  padding: 20px;
  padding-top: 18px;
  padding-bottom: 17px;
  border: var(--border-variant-2);
  flex-grow: 1;
  height: 60px;
  font: var(--font-variant-2);
  border-right: none;
}

.share-bar__wrapper {
  padding: 25px;
  padding-top: 0;
  width: 100%;
  display: flex;
  cursor: unset;
}

.share-bar-end {
  background-color: var(--color-accent);
  padding: 6px 10px 5px 10px;
  height: 37px;
  font: var(--font-variant-2);
  border: var(--border-variant-2);
  border-left: (--border-variant-2);
  cursor: pointer;
}

.share-bar-end .icon {
  color: white;
}

.share-bar {
  background-color: #fff;
  outline: none;
  width: 100%;
  padding-left: 10px;
  padding-top: 6px;
  padding-bottom: 5px;
  padding-right: 0;
  border: var(--border-variant-2);
  flex-grow: 1;
  height: 37px;
  font: var(--font-variant-2);
  border-right: none;
}

.search-input:focus + .search-bar-end .icon {
  color: var(--color-accent);
  transition-duration: 200ms;
}

.search-bar-end {
  background-color: #fff;
  padding: 20px;
  padding-top: 18px;
  height: 60px;
  font: var(--font-variant-2);
  border: var(--border-variant-2);
  border-left: none;
}

.contains-bar-end {
  background-color: var(--color-accent);
  padding: 18px;
  padding-top: 18px;
  height: 60px;
  font: var(--font-variant-2);
  border: var(--border-variant-2);
  border-left: none;
}

.contains-bar-end .icon {
  color: white !important
}

.search-filter__outer-wrapper {
  width: 100%;
  display: flex;
  flex-flow: column;
}

.sidebar__main {
  overflow-y: auto;
  height: 100%;
}

.search-filter__inner-wrapper {
  width: 100%;
  display: flex;
  cursor: unset;
}

.search-dropdown {
  width: 100%;
  background-color: white;
  max-height: 0;
  overflow-y: auto;
  font: var(--font-variant-1);
  border: var(--border-variant-2);
  border-top: none;
  border-bottom: none;
}

.search-dropdown.active {
  max-height: 200px;
  border-bottom: var(--border-variant-2);
}

.search-dropdown > div {
  padding: 5px 20px
}

.search-dropdown > div:first-child {
  margin-top: 15px
}

.search-dropdown > div:first-child:last-child {
  margin-bottom: 15px;
}

.search-dropdown > div:hover {
  background-color: #dfdfdf;
  cursor: pointer;
}

.hidden {
  display: none !important
}

.search-multi-select-selected-item {
  background-color: var(--color-dark);
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  color: white;
  padding: 12px 20px 11px 20px;
  font: var(--font-variant-1);
  border-radius: 20px;
}

.search-multi-select-selected-item:first-child:not(:last-child), .search-multi-select-selected-item:not(:first-child):not(:last-child) {
  margin-bottom: 10px;
}

.search-multi-select-selected-item-m {
  background-color: var(--color-dark);
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  color: white;
  padding: 12px 20px 11px 20px;
  font: var(--font-variant-1);
  border-radius: 20px;
  max-width: fit-content;
  margin-top: 10px;
  height: fit-content
}

.search-multi-select-selected-item-m > .icon{
  margin-left: 10px;
}

.search-multi-select-selected-item-m:first-child:not(:last-child), .search-multi-select-selected-item-m:not(:first-child):not(:last-child) {
  margin-right: 10px;
}


.search-multi-select-selected__wrapper {
  padding-top: 20px;
  padding-bottom: 20px
}

.multi-select-selected__wrapper-m {
  display: flex;
  flex-flow: row wrap;
}

.search-multi-select-selected__wrapper.empty {
  padding-top: 0;
  padding-bottom: 0
}


.custom-select__inner-wrapper {
  position: relative;
  z-index: 1;
  background-color: var(--color-shade-1);
}

.custom-select__inner-wrapper-modal {
  position: relative;
  z-index: 1;
  background-color: white;
}

.custom-select__inner-wrapper.small {
  width: 90px;
  background-color: white;
}

.dashboard__wrapper {
  width: 100%;
  position: relative;
}

.dropdown-icon {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.range-rep__wrapper {
  width: 100%;
  height: 16px;
  margin-top: 22.5px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  position: relative;
}

.range-lower__spotter, .range-upper__spotter {
  position: absolute;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background-color: white;
  border: 2px solid #BC3733;
  transform: translateX(-8px);
  z-index: 4;
}

.range-indicator {
  position: absolute;
  height: 2px;
  background-color: #BC3733;
  z-index: 3;
}

.range-line {
  border-top: 1px solid #A1A1A1;
}

.custom-toggle__wrapper {
  display: flex;
  flex-flow: row;
  align-items: center;
  margin-left: 20px;
}

.multi-select-selected__wrapper.empty > .clear-selected {
  display: none;
}

.clear-selected {
  background-color: #ffffff;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  color: white;
  padding: 12px 20px 11px 20px;
  font: var(--font-variant-1);
  border-radius: 20px;
  max-width: -moz-fit-content;
  max-width: fit-content;
  border: 1px solid #CFD0CF;
  margin-top: 10px;
  color: #A1A1A1;
  cursor: pointer;
  height: fit-content;
}

.contact-request-form__body {
  overflow-y: auto;
  height: 100%;
  padding: 40px;
}

.contact-request-form__body.no-pad {
  padding: 0;
}

button#csv {
  width: fit-content;
  flex: unset;
  margin-top: 20px;
  padding: 10px 45px;
}

.custom-toggle {
  background-color: #efeded;
  display: flex;
  flex-flow: row;
  height: fit-content;
  border-radius: 20px;
  padding: 5px;
  box-shadow: inset 0px 4px 4px rgb(0 0 0 / 10%);
  cursor: pointer
}

.modal__error {
  border: 1px solid #B3121F;
  /* Drop Shadow */
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.2);
  width: 100%;
  padding: 20px;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  font: var(--font-variant-1);
  text-transform: uppercase;
  color: #B3121F;
  background-color: white;
}

.loader-status__wrapper {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0 40px 20px 40px
}

.loader-status {
  border: 1px solid var(--color-dark);
  /* Drop Shadow */
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.2);
  width: 100%;
  padding: 20px;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  font: var(--font-variant-1);
  text-transform: uppercase;
  color: var(--color-dark);
  background-color: white;
}

.error-closer {
  color: #B3121F;
  cursor: pointer;
}

.modal-error__wrapper {
  position: absolute;
  bottom: 90px;
  width: 100%;
}

.modal-error__wrapper.mx {
  padding: 0 40px 20px 40px
}

.custom-toggle__option {
  padding: 8px 18px;
  font: var(--font-variant-1);
  display: flex;
  flex: 1 1 100%;
  text-align: center;
  justify-content: center;
}

.custom-modal-toggle__option:not(.active) {
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: 1px solid #CFD0CF;
  color: #A1A1A1
}

.custom-toggle__option.active {
  background-color: #b3121f;
  border-radius: 20px;
  color: white;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 10%);
}

.modal__bg {
  position: absolute;
  background-color: #000000bf;
  height: 100%;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 12;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
}

.contacts-modal__bg {
  justify-content: flex-end;
}

.contacts-modal {
  width: 50%;
  background-color: white;
  max-width: 800px;
  position: relative;
  height: 100%;
  cursor: default;
}

.share-modal {
  min-width: 400px;
  background-color: white;
  max-width: 800px;
  position: relative;
  height: fit-content;
  cursor: default;
  border-radius: 10px;
}

.modal-closer {
  cursor: pointer;
}

.modal-row-item__top-level-heading {
  font: var(--font-variant-7);
}

.modal-row-item__top-level {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.custom-modal-toggle {
  display: flex;
  font: var(--font-variant-1);
  width: 132px;
  justify-content: center;
  text-transform: uppercase;
}

.custom-modal-toggle__option {
  text-align: center;
  width: 50%;
  padding-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;
}

.custom-modal-toggle__option.active {
  text-align: center;
  width: 50%;
  background-color: var(--color-accent);
  color: white;
  border: 1px solid transparent;
}


.contact-request-form-title, .share-modal-form-title {
  font: var(--font-variant-3);
  margin: 0;
  width: fit-content;
}

.contact-request-form-title__wrapper, .share-modal-title__wrapper {
  border-bottom: var(--border-variant-1);
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  padding: 25px 45px 26px 40px;
}

.share-modal-title__wrapper {
  border-bottom: none;
}

.contact-request-form__input {
  font: var(--font-variant-2);
  padding: 18px 0 17px 20px;
  display: block;
  width: 100%;
  border: 1px solid #A1A1A1;
  outline: none;
}

.contact-request-form__select-wrapper-title {
  color: #a1a1a1;
  font: var(--font-variant-5);
  font-weight: 700;
}

.contact-request-form__select-wrapper-title_dark {
  color: #151313;
}

.checkbox-list__wrapper {
  display: flex;
  flex-flow: row wrap;
}

.checkbox-list__wrapper > div {
  width: 25%;
  margin-top: 22px;
  font: var(--font-variant-4);
}

.modal-row-item.small {
  padding: 20px 40px;
}

.modal-row-item.large {
  padding: 40px;
}

.modal-row-item {
  border-bottom: var(--border-variant-1);
  display: flex;
  flex-flow: column;
  align-items: center;
}

.modal-row-item > div {
  width: 100%;
}

.modal-row-item > div:not(:first-child) {
  margin-top: 20px;
}

input[type="checkbox"] {
  margin: 0;
}

input[type="checkbox"] + label {
  margin-left: 10px;
}

.checkbox-container {
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-left: 30px;
  width: fit-content;
}

.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: transparent;
  border: 2px solid #A1A1A1;
  padding: 2px;
}

.checkmark__inner {
  display: block;
  width: 100%;
  height: 100%;
}

.checkbox-container:hover input ~ .checkmark {
  background-color: rgb(255, 143, 143);
}

.checkbox-container input:checked ~ .checkmark > .checkmark__inner {
  background-color: #b3121f;
}

.cr-table {
  width: 100%;
}

.cr-table th {
  text-align: left;
  font: var(--font-variant-5);
  color: #A1A1A1;
  font-weight: 700;
}

.cr-table td {
  text-align: left;
  font: var(--font-variant-6);
}

.cr-table > thead > tr {
  margin-bottom: 10px;
}

.contact-request-form-paragraph {
  font: var(--font-variant-4)
}

.expander, .expandable.collapsed, .expandable.expanded {
  cursor: pointer;
}

.loader__overlay {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  height: 100%;
  background-color: #ffffffc9;
  z-index: 10;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.search-multi-select-selected-item > .icon, .search-multi-select-selected-item-m {
  cursor: pointer;
}

.tooltip-opener {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltip-opener .tooltip-descriptor {
  visibility: hidden;
  padding: 30px;
  background: #FFFFFF;
  box-shadow: 0px 7.45833px 9.94444px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
 
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 10;
  bottom: 100%;
  left: 50%;
  width: 300px;
  margin-left: -150px;
  transition-duration: 0ms !important;
}

.tooltip-descriptor > * {
  transition-duration: 0ms !important;
}

.tooltip-link {
  font: 12px/13.8px helvetica;
  color: #B3121F;
  text-decoration: none;
}

.tooltip-sub {
  font: 12px/13.8px helvetica;
}

.tooltip-link:hover {
  text-decoration: underline;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip-opener:hover .tooltip-descriptor {
  visibility: visible;
}

.tooltip-title {
  font: 16px/18.4px helvetica;
  margin: 0;
  margin-bottom: 12px;
}